.user-wallet-create{
    .column{
        flex-direction: column;
    }
    .error-validation {
        p{
            font-size: 0.8125rem;
        }
    }
}

.user-create-pop {
    .popup-wrapper {
        @media (max-width: 768px) {
            max-height: 36rem !important;
        }
    }

}