.get-community {
    min-width: 100%;
    width: 50rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--cu-black-100);
    background-color: var(--cu-black-900);
    border-radius: 0.5rem;
    max-height: 93dvh;
    .cu-table table td:first-child {
        position: unset;
    }
    .cu-table-responsive{
        overflow: unset;
    }
.infinite-scroll-component{
    max-height: calc(100dvh - 20rem);
    .details-skeleton {
        grid-template-columns:unset;
        .skeleton-wrapper{
            aspect-ratio: unset;
            height: 4rem;
        }
    }
}
    @media screen and (max-width: 768px) {
        width: 100dvw;
    }

    h2 {
        color: var(--cu-white-200);
    }

    tbody {
        tr {
            cursor: pointer;
        }
    }

    .active {
        background-color: var(--cu-gray-940);
        border: 1px solid var(--cu-white-200);

        td {
            border-bottom: none !important;
        }

    }

    .in-active {}
}