$header-height: 7.71rem;
$sidebar-width: 22rem;
$sideBarRsBreak: 768px;



.login {
    &-layout {
        background-image: url(~/src/Assets/Images/login.png);
        background-size: cover;
        padding: 5.71rem 5.71rem 3rem;
        height: 100%;
        overflow: auto;
        scroll-behavior: smooth;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-wrapper {
            max-width: 32.14rem;
            width: 100%;
            min-height: 27.14rem;
        }

        &-title {
            color: var(--cu-white);
        }

        &-font {
            font-size: 1.28rem;
        }

        &-description {
            p {
                color: var(--cu-white);
                font-size: 1.28rem;
            }

            &-max {
                max-width: 26.28rem;
            }

        }

    }

    &-footer {
        p {
            color: var(--cu-white-50);
        }
    }
}

.dashboard {
    &-layout {
        height: 100%;
        background: var(--cu-black-800);

        &-header {
            height: $header-height;
            display: flex;
            align-items: center;
            @include hr-border(bottom);
        }

        &-headerArea {
            @include padding-left-fullWrapper;
            @include padding-right-fullWrapper;
        }

        &-main {
            height: calc(100% - $header-height);
            display: flex;
        }

        &-content-area {
            @include padding-left-fullWrapper;
            @include padding-right-fullWrapper;
            padding-top: 1.78rem;
            @include scroll;
        }

        &-right {
            flex: 1 1 calc(100% - $sidebar-width);
        }

        &-left {
            flex: 1 1 $sidebar-width;
            @include hr-border(right);
        }

        &-logo {
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 5.71rem;

            &-mobile {
                min-height: 10rem;
                display: none;

                @media (max-width: $sideBarRsBreak) {
                    display: flex;
                }
            }

            @media (max-width: $sideBarRsBreak) {
                display: none;
            }
        }

        &-sidebar {
            @include scroll;

            @media (max-width: $sideBarRsBreak) {
                position: fixed;
                @apply inset-0;
                max-width: 20rem;
                background: var(--cu-black);
                transition: all 1s ease;
                transform: translateX(-100%);
                z-index: 9;
            }

            &.navOpen {
                transform: translateX(0);
            }
        }

        &-overlay {
            @media (max-width: $sideBarRsBreak) {
                position: fixed;
                @apply inset-0;
                background: var(--cu-black-trans-500);
                transition: all 1s ease;
                opacity: 0;
                visibility: hidden;
                z-index: 8;
            }

            &.navOpen {
                opacity: 1;
                visibility: visible;
            }
        }

        &-mobileMenu {
            display: none;

            @media (max-width: $sideBarRsBreak) {
                display: flex;
                margin-left: -1rem;
            }
        }
    }
}




