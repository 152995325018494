.communities-report {
    table {
      
      td:not(:first-child),td .name-span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 8.5rem;
      }
      .tooltip-wrap {
        position: relative;
        &[tooltip]:hover::after {
            content: attr(tooltip);
            position: fixed;
            padding: 0.3125rem 0.75rem;
            transform: translate(36%, 94%);
            font-size: 0.75rem;
            border-radius: 0.75rem;
            border:1px solid var(--dark-mode-300); ;
            background: var(--Neutral-900, #0D0F11);
            width: max-content;
            margin-bottom: 5px;
            display: inline-block;
            z-index: 2;
          }
    }
      td .name-span{
        max-width: 8.625rem;
      }
      tbody {
        tr {
          cursor: default !important;
        }
      }
    }
  }
  