
.collector-card-screenshot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48.5rem;
    gap: 1rem;
    background: #191D23;
    border-radius: var(--normal-border-radius);
    overflow: hidden;
    border: 1px;
    height: 25.35rem;
    position: absolute;
    opacity: 0;
    .head-section {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        justify-content: center;
    }
    .cover-image {
        width: 100%;
        padding: 1rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .profile-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img {
            object-fit: cover;
            border-radius: 50%;
            background: var(--cu-black-500);
            width: 6rem;
            height: 6rem;
            border: 1px solid var(--cu-black-400);
        }
    }
    .blockchain-section {
        .blockchain-container {
            display: flex;
            align-items: center;
        }
        .blockchain-image {
            width: 2rem;
            height: 2rem;
            .count-text {
                position: absolute;
                inset: 0;
                background: rgba(0, 0, 0, 0.4117647059);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                color: #E7EAEE;
                border-radius: 1rem;
            }
        }
    }
    .creator-name {
        display: flex;
        align-items: center;        
        p { 
            font-size: 2rem;
            color: #E7EAEE;
            text-align: left;
            margin-bottom: 2rem;
        }
    }
}